import React, { useState } from "react";
import style from './index.module.less'
import { Layout, SEO } from '@/components/index'
import { useIntl } from "react-intl";
import LinkSVG from '@/images/SVG/manual_link_icon.svg'
import useBigScreen from "@/hooks/bigScreen"
import useNavigate from '@/hooks/useNavigate'
import getCountry from '@/utils/getCountry.js'

const Index = () => {
    const intl = useIntl()
    const ifPc = useBigScreen(768)
    const region = getCountry()

    const [activeItem, setActiveItem] = useState(0)
    const ifNoShowOne = region == 'th'

    const list = [
        {
            type: "One",
            list: [
                {
                    name: "XREAL_One_User_Manuals",
                    link: "/support/XREAL_One_User_Manuals"
                }
            ]
        },
        {
            type: "Air 2 Ultra",
            list: [
                {
                    name: "Air_2_Ultra_User_Manuals",
                    link: "/support/Air_2_Ultra_User_Manuals"
                }, {
                    name: "Glasses_Button_Functions",
                    link: "/support/Glasses_Button_Functions"
                }
            ]
        },
        {
            type: "Air 2 Pro",
            list: [
                {
                    name: "Air_2_Pro_User_Manuals",
                    link: "/support/XREAL_Air2Pro_Quick_Start_Guide_pdf"
                }
            ]
        },
        {
            type: "Air 2",
            list: [
                {
                    name: "Air_2_User_Manuals",
                    link: "/support/XREAL_Air2_Quick_Start_Guide_pdf"
                }
            ]
        },
        {
            type: "Air",
            list: [
                {
                    name: "Air_User_Manuals",
                    link: "/support/XREAL_AIR_MANUAL_EN&JP&KR&CN_pdf"
                }
            ]
        },
        {
            type: "Light",
            list: [
                {
                    name: "Light_User_Manuals",
                    link: "/support/Nreal_Light_After_Sales_&_Warranty_US_pdf"
                }
            ]
        },
        {
            type: "XREAL Beam Pro",
            list: [
                {
                    name: "XREAL Beam_Pro_User_Manuals",
                    link: "/support/XREAL_Beam_Pro_User_Manuals"
                }
            ]
        }, {
            type: "Beam",
            list: [
                {
                    name: "Beam_User_Manuals",
                    link: "/support/XREAL_Beam_MANUAL_CN&EN&JP&KR_pdf"
                }
            ]
        }
        , {
            type: "Adapter",
            list: [
                {
                    name: "Adapter_User_Manuals",
                    link: "/support/XREAL_ADA_MANUAL_pdf"
                }
            ]
        }
    ]

    const list_no_one = [
        {
            type: "Air 2 Ultra",
            list: [
                {
                    name: "Air_2_Ultra_User_Manuals",
                    link: "/support/Air_2_Ultra_User_Manuals"
                }, {
                    name: "Glasses_Button_Functions",
                    link: "/support/Glasses_Button_Functions"
                }
            ]
        },
        {
            type: "Air 2 Pro",
            list: [
                {
                    name: "Air_2_Pro_User_Manuals",
                    link: "/support/XREAL_Air2Pro_Quick_Start_Guide_pdf"
                }
            ]
        },
        {
            type: "Air 2",
            list: [
                {
                    name: "Air_2_User_Manuals",
                    link: "/support/XREAL_Air2_Quick_Start_Guide_pdf"
                }
            ]
        },
        {
            type: "Air",
            list: [
                {
                    name: "Air_User_Manuals",
                    link: "/support/XREAL_AIR_MANUAL_EN&JP&KR&CN_pdf"
                }
            ]
        },
        {
            type: "Light",
            list: [
                {
                    name: "Light_User_Manuals",
                    link: "/support/Nreal_Light_After_Sales_&_Warranty_US_pdf"
                }
            ]
        },
        {
            type: "XREAL Beam Pro",
            list: [
                {
                    name: "XREAL Beam_Pro_User_Manuals",
                    link: "/support/XREAL_Beam_Pro_User_Manuals"
                }
            ]
        }, {
            type: "Beam",
            list: [
                {
                    name: "Beam_User_Manuals",
                    link: "/support/XREAL_Beam_MANUAL_CN&EN&JP&KR_pdf"
                }
            ]
        }
        , {
            type: "Adapter",
            list: [
                {
                    name: "Adapter_User_Manuals",
                    link: "/support/XREAL_ADA_MANUAL_pdf"
                }
            ]
        }
    ]
    const list_show = ifNoShowOne ? list_no_one : list
    return (
        <Layout menuBlack={true}>
            <SEO page="User Manual" />
            <div className={style.box}>
                <div className={style.title}>
                    {intl.formatMessage({ id: "support_manuals" })}
                </div>
                {ifPc ? <div className={style.guide}>
                    <div className={style.guideLeft}>
                        {list_show.map((item, index) => (
                            <div className={style.guideLeftItem} key={`support_guide_type${index}`} style={{ borderBottom: activeItem == index ? "1px solid #000" : "1px solid transparent" }}
                                onClick={() => setActiveItem(index)}
                            >
                                <span className={style.guideTypeTitle}
                                    style={{ color: activeItem == index ? "#000" : "rgba(0,0,0,0.2)" }}

                                >
                                    {item.type}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className={style.guideRight}>
                        {list_show[activeItem].list.map((item, index) => (
                            <div className={style.guideRightItem} key={`support_guide_type${activeItem}_manual${index}`}
                                onClick={() => {
                                    if (!!item.linkOut) {
                                        window.open(item.link)
                                    } else {
                                        useNavigate(item.link, 'out')
                                    }

                                }}
                            >
                                <div className={style.guideManualName}>{item.name}</div>
                                <LinkSVG />
                            </div>
                        ))}
                    </div>
                </div> :
                    <div className={style.guideM}>
                        {list.map((item, index) => (
                            <div className={style.guideMItem} key={`support_guide_mobile_type${index}`}>
                                <div className={style.guideTypeTitle}>{item.type}</div>
                                <div className={style.guideMItemList}>
                                    {item.list.map((value, key) => (
                                        <div className={style.guideMItemListItem} key={`support_guide_mobile_type${index}_manual${key}`}
                                            onClick={() => window.open(value.link)}
                                        >
                                            <div className={style.guideManualName}>{value.name}</div>
                                            <LinkSVG />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>}


            </div>
        </Layout>
    )
}

export default Index